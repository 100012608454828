import { isAfter, isBefore, isEqual } from 'date-fns'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { isValidPhoneNumber as isValidMobileNumber } from 'libphonenumber-js/mobile'
import { extend } from 'vee-validate'
import { digits, integer, required } from 'vee-validate/dist/rules'
import { parse } from 'vue-currency-input'
import { CURRENCY_FORMAT_OPTIONS } from '~/constants'
import { isAbnLength, isAcnLength, toCurrency } from '.'

const ABN_VALIDATION_MESSAGES = {
  mustBeDigits: 'An ABN must only include digits',
  mustBeElevenCharacters: 'An ABN must consist of 11 digits',
  invalid: 'Invalid ABN'
}

const ACN_VALIDATION_MESSAGES = {
  mustBeDigits: 'An ACN must only include digits',
  mustBeNineCharacters: 'An ACN must consist of 9 digits',
  invalid: 'Invalid ACN'
}

const DATE_RANGE_MESSAGES = {
  withInYear: 'Day must be with in 1-365 range',
  mustBeDigits: 'Day must only include digits'
}

const ABN_OR_ACN_VALIDATION_MESSAGES = {
  mustBeDigits: 'An ABN or ACN must only include digits',
  mustBeNineOrElevenCharacters: 'ABN/ACN must be 9 or 11 digits'
}

export const VALIDATION_MESSAGES = {
  required: 'This field is required',
  email: 'This field must be a valid email',
  attribute: 'This field must have length less than 255 characters',
  name: 'Name must have length less than 1000 characters',
  address: 'Address must have length less than 500 characters',
  notes: 'Notes must have length less than 500 characters',
  bankName: 'Bank Name must have length less than 250 characters',
  projectAddress: 'Contract Name/Project Address must have length less than 500 characters',
  referenceNumber: 'Reference number must have length less than 255 characters',
  guaranteeNumber: 'Guarantee number must have length less than 255 characters',
  agreementContent: 'Agreement Content must have length less than 500 characters',
  noPoBox: 'Address must not be a PO Box',
  between: (_: string, params: Record<string, any> | undefined) =>
    `Value must be between ${parseInt(params!.min)} and ${parseInt(params!.max)}`,
  amount: 'Amount must be between $1 and $1,000,000,000',
  digits: (_: string, params: Record<string, any> | undefined) =>
    `This field must be numerical and exactly contain ${params!.length} digits`,
  integer: 'This field must be numerical only',
  currency: 'This field must be a valid currency value',
  password: 'Password must contain 8 characters with upper case, lower case, a number and a special character',
  passwordMatch: 'Password confirmation does not match',
  acceptTerms: "You must agree to the T&C's and Privacy Policy",
  acceptBrokerTerms: 'You must agree to the Privacy Policy and Brokerage',
  accountNumber: 'The Account number must only contain numbers and be between 6 and 10 digits',
  accountBsb: 'The BSB must be 6 digits, optionally separated by a hyphen/space (e.g. 000 000)',
  accountNumberDigits: 'The account number must be between 6 to 10 digits in length',
  accountBsbDigits: 'The BSB must be 6 digits(e.g. 000 000)',
  acceptSubmissionTerms: 'You must agree to the Terms of Submission',
  acceptBondSubmissionTerms: 'You must agree to the Terms of Submission and Brokerage',
  megSelectCompanyType: 'Please select at least one company type',
  min: (_: string, params: Record<string, any> | undefined) =>
    `Value must be at least ${toCurrency(parseInt(params!.min), true)}`,
  min_value: (_: string, params: Record<string, any> | undefined) => `Value must be ${params!.min} or more`,
  max_value: (_: string, params: Record<string, any> | undefined) => `Value must be ${params!.max} or less`,
  phoneNumber: 'Please enter a valid phone number (eg:0412345678)',
  australianPhoneNumber: 'Please enter a valid phone number (eg:0812345678 or +61812345678)',
  postCode: 'Please enter a valid post code (eg:2000)',
  mobilePhoneNumber: 'Please enter a valid mobile number (eg:0412345678)',
  internationalMobilePhoneNumber: 'Please enter a valid mobile number in international format (eg:+61412345678)',
  confirmValid: 'You must confirm that the details are correct.',
  url: 'This is not a valid URL',
  individualDetails: 'Incomplete information, please edit the details to complete the application.',
  fileExtension: 'File name must have a valid file extension.',
  guid: 'must be a valid guid',
  mustNotMatch: (_: string, params: Record<string, any>) => `${params!.message}`
}

const validateABN = (abn: string) => {
  abn = abn.replace(/\s/g, '') // Remove all spaces
  if (!(abn && /^\d+$/.test(abn))) return ABN_VALIDATION_MESSAGES.mustBeDigits
  if (!isAbnLength(abn)) return ABN_VALIDATION_MESSAGES.mustBeElevenCharacters

  let weightedSum = 0
  const weight = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19]
  for (var i = 0; i < weight.length; i++) {
    weightedSum += (parseInt(abn[i]) - (i === 0 ? 1 : 0)) * weight[i]
  }
  if (weightedSum % 89 !== 0) return ABN_VALIDATION_MESSAGES.invalid
  return true
}

const validateDateRange = (day: string | number) => {
  day = day.toString().replace(/\s/g, '') // Remove all spaces
  let convertedDay = Number(day)
  if (!(day && /^\d+$/.test(day))) return DATE_RANGE_MESSAGES.mustBeDigits
  if (convertedDay < 1 || convertedDay > 365) return DATE_RANGE_MESSAGES.withInYear

  return true
}

const validateAbnOrAcn = (abnOrAcn: string) => {
  abnOrAcn = abnOrAcn.replace(/\s/g, '') // Remove all spaces
  if (!(abnOrAcn && /^\d+$/.test(abnOrAcn))) return ABN_OR_ACN_VALIDATION_MESSAGES.mustBeDigits

  if (isAcnLength(abnOrAcn)) {
    return validateAcn(abnOrAcn)
  } else if (isAbnLength(abnOrAcn)) {
    return validateABN(abnOrAcn)
  }

  return ABN_OR_ACN_VALIDATION_MESSAGES.mustBeNineOrElevenCharacters
}

const validateAcn = (acn: string) => {
  acn = acn.replace(/\s/g, '') // Remove all spaces
  if (!(acn && /^\d+$/.test(acn))) return ACN_VALIDATION_MESSAGES.mustBeDigits
  if (!(acn && /^\d{9}$/.test(acn))) return ACN_VALIDATION_MESSAGES.mustBeNineCharacters

  let weightedSum = 0
  const weight = [8, 7, 6, 5, 4, 3, 2, 1]
  for (var i = 0; i < weight.length; i++) {
    weightedSum += parseInt(acn[i]) * weight[i]
  }
  const remainder = weightedSum % 10
  const expectedCheckDigit = remainder == 0 ? 0 : 10 - remainder
  if (expectedCheckDigit !== parseInt(acn[8])) return ACN_VALIDATION_MESSAGES.invalid
  return true
}

export const validateEmailAddress = (email: string) =>
  Boolean(email.match(/^(?!.*?\.\.)[a-zA-Z0-9_.+-]*[a-zA-Z0-9]+@[a-zA-Z0-9-.]+\.[a-zA-Z0-9]{2,}$/))

export const validatePostCode = (postCode: string) => Boolean(postCode.match(/\b\d{4}\b/))

export const validateAustralianAddress = (address: string) => {
  var addressValidator = /^[^,]+,\s*[^,]+,\s*[A-Za-z\s\d]+$/
  return addressValidator.test(address)
}

const camelCaseToSentence = (str: string) => {
  const sentence = str.replace(/([^a-z])/gi, '').replace(/([A-Z])/g, ' $1')
  return sentence.charAt(0).toUpperCase() + sentence.slice(1)
}

/* eslint-disable-next-line */
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`!~<>,;:_=?*#."&%()|[\]{}\-+'$^@\/\\])[A-Za-z\d`!~<>,;:_=?*#."&%()|[\]{}\-+'$^@\/\\]{8,}$/

const validateBsbNumber = (bsbNumber: string) => {
  bsbNumber = bsbNumber.replace(/\s/g, '') // Remove all spaces
  if (!(bsbNumber && /^\d{6}$/.test(bsbNumber))) return VALIDATION_MESSAGES.accountBsbDigits

  return true
}

const validateAccountNumber = (accNumber: string) => {
  accNumber = accNumber.replace(/\s/g, '') // Remove all spaces
  if (!(accNumber && /^\d{6,10}$/.test(accNumber))) return VALIDATION_MESSAGES.accountNumberDigits

  return true
}

const validateAustralianPhoneAndMobileNumber = (phoneNumber: string) => {
  phoneNumber = phoneNumber.replace(/\s/g, '') // Remove all spaces
  if (!(phoneNumber && /^\+?[0-9]{6,15}$/.test(phoneNumber))) return VALIDATION_MESSAGES.australianPhoneNumber
  return true
}

export const registerValidators = () => {
  extend('required', {
    ...required,
    message: VALIDATION_MESSAGES.required
  })

  extend('email', {
    validate: value => validateEmailAddress(value),
    message: VALIDATION_MESSAGES.email
  })

  extend('attribute', {
    validate: value => value.length <= 255,
    message: VALIDATION_MESSAGES.attribute
  })

  extend('name', {
    validate: value => value.length <= 1000,
    message: VALIDATION_MESSAGES.name
  })

  extend('address', {
    validate: value => value.length <= 500,
    message: VALIDATION_MESSAGES.address
  })

  extend('notes', {
    validate: value => value.length <= 500,
    message: VALIDATION_MESSAGES.notes
  })

  extend('bankName', {
    validate: value => value.length <= 250,
    message: VALIDATION_MESSAGES.bankName
  })

  extend('projectAddress', {
    validate: value => value.length <= 250,
    message: VALIDATION_MESSAGES.projectAddress
  })

  extend('referenceNumber', {
    validate: value => value.length <= 255,
    message: VALIDATION_MESSAGES.referenceNumber
  })

  extend('guaranteeNumber', {
    validate: value => value.length <= 255,
    message: VALIDATION_MESSAGES.guaranteeNumber
  })

  extend('agreementContent', {
    validate: value => value.length <= 500,
    message: VALIDATION_MESSAGES.agreementContent
  })

  extend('amount', {
    validate: value => value >= 1 && value < 1000000000,
    message: VALIDATION_MESSAGES.amount
  })

  extend('digits', {
    ...digits,
    message: VALIDATION_MESSAGES.digits
  })

  extend('integer', {
    ...integer,
    message: VALIDATION_MESSAGES.integer
  })

  extend('currency', {
    validate: value => /^\d+(\.\d{2})?$/.test(value),
    message: VALIDATION_MESSAGES.currency
  })

  extend('abn', value => validateABN(value))

  extend('abnOrAcn', value => validateAbnOrAcn(value))

  extend('dateRange', value => validateDateRange(value))

  extend('mobileNumber', {
    validate: value => isValidMobileNumber(value, 'AU'),
    message: VALIDATION_MESSAGES.mobilePhoneNumber
  })

  extend('internationalMobileNumber', {
    validate: value => /^\+[0-9]{10,15}$/.test(value),
    message: VALIDATION_MESSAGES.internationalMobilePhoneNumber
  })

  extend('phoneNumber', {
    validate: value => isValidPhoneNumber(value, 'AU'),
    message: VALIDATION_MESSAGES.phoneNumber
  })

  extend('australianPhoneNumber', value => validateAustralianPhoneAndMobileNumber(value))

  extend('postCode', {
    validate: value => validatePostCode(value),
    message: VALIDATION_MESSAGES.postCode
  })

  extend('password', {
    validate: value => passwordRegex.test(value),
    message: VALIDATION_MESSAGES.password
  })

  extend('acceptTerms', {
    params: ['otherTerms'],
    validate: (terms, { otherTerms }: any) => terms === true && otherTerms === true,
    message: VALIDATION_MESSAGES.acceptTerms
  })

  extend('acceptBrokerTerms', {
    params: ['privacyTerms'],
    validate: (terms, { privacyTerms }: any) => terms === true && privacyTerms === true,
    message: VALIDATION_MESSAGES.acceptBrokerTerms
  })

  extend('acceptSubmissionTerms', {
    validate: terms => terms === true,
    message: VALIDATION_MESSAGES.acceptSubmissionTerms
  })

  extend('acceptBondSubmissionTerms', {
    params: ['submissionTerms'],
    validate: (terms, { submissionTerms }: any) => terms === true && submissionTerms === true,
    message: VALIDATION_MESSAGES.acceptBondSubmissionTerms
  })

  extend('date_before', {
    params: ['otherDate', 'otherDateName'],
    validate: (date, { otherDate }: any) => {
      if (!otherDate) return true
      const parsedDate = new Date(date)
      const parsedOther = new Date(otherDate)
      if (!parsedDate || !parsedOther) {
        return false
      }
      return isEqual(parsedDate, parsedOther) || isBefore(parsedDate, parsedOther)
    },
    message: (field: string, props: any) => {
      const { otherDate, otherDateName } = props
      return `${camelCaseToSentence(field)} must be before ${camelCaseToSentence(otherDateName || otherDate)}`
    }
  })

  extend('date_after', {
    params: ['otherDate', 'otherDateName'],
    validate: (date, { otherDate }: any) => {
      if (!otherDate) return true
      const parsedDate = new Date(date)
      const parsedOther = new Date(otherDate)
      if (!parsedDate || !parsedOther) {
        return false
      }
      return isEqual(parsedDate, parsedOther) || isAfter(parsedDate, parsedOther)
    },
    message: (field: string, props: any) => {
      const { otherDate, otherDateName } = props
      return `${camelCaseToSentence(field)} must be after ${camelCaseToSentence(otherDateName || otherDate)}`
    }
  })

  extend('accountNumber', {
    validate: value => /^\d{6,10}$/.test(value),
    message: VALIDATION_MESSAGES.accountNumber
  })

  extend('accountBsb', {
    validate: value => /^\d{3}(-?|\s?)\d{3}$/.test(value),
    message: VALIDATION_MESSAGES.accountBsb
  })

  extend('accountNumberDigits', value => validateAccountNumber(value))

  extend('accountBsbDigits', value => validateBsbNumber(value))

  extend('noPoBox', {
    validate: value => {
      let words = value.split(' ')
      return !words.includes('box') && !words.includes('po')
    },
    message: VALIDATION_MESSAGES.noPoBox
  })

  extend('between', {
    params: ['min', 'max'],
    validate: (value, { min, max }: any) => parseInt(value) >= min && parseInt(value) <= max,
    message: VALIDATION_MESSAGES.between
  })

  extend('confirmValid', {
    validate: value => value,
    message: VALIDATION_MESSAGES.confirmValid
  })

  extend('min', {
    params: ['min'],
    validate: (value, { min }: any) => {
      const formattedValue =
        typeof value === 'string' && value?.includes('$') ? parse(value, CURRENCY_FORMAT_OPTIONS) : parseInt(value)
      return formattedValue! >= min
    },
    message: VALIDATION_MESSAGES.min
  })

  extend('min_currency', {
    params: ['min'],
    validate: (value, { min }: any) => {
      if (!min) return true

      const formattedValue =
        typeof value === 'string' && value?.includes('$') ? parse(value, CURRENCY_FORMAT_OPTIONS) : parseInt(value)
      const formattedMin =
        typeof value === 'string' && value?.includes('$') ? parse(min, CURRENCY_FORMAT_OPTIONS) : parseInt(min)

      return formattedValue! >= formattedMin!
    },
    message: VALIDATION_MESSAGES.min_value
  })

  extend('max_currency', {
    params: ['max'],
    validate: (value, { max }: any) => {
      if (!max) return true

      const formattedValue =
        typeof value === 'string' && value?.includes('$') ? parse(value, CURRENCY_FORMAT_OPTIONS) : parseInt(value)
      const formattedMax =
        typeof value === 'string' && value?.includes('$') ? parse(max, CURRENCY_FORMAT_OPTIONS) : parseInt(max)

      return formattedValue! <= formattedMax!
    },
    message: VALIDATION_MESSAGES.max_value
  })

  extend('min_value', {
    params: ['min'],
    validate: (value, { min }: any) => {
      return parseInt(value) >= min
    },
    message: VALIDATION_MESSAGES.min_value
  })

  extend('max_value', {
    params: ['max'],
    validate: (value, { max }: any) => {
      return parseInt(value) <= max
    },
    message: VALIDATION_MESSAGES.max_value
  })

  extend('mustNotMatch', {
    params: ['message', 'regex'],
    validate: (value, { message, regex }: any) => {
      const matcher = new RegExp(regex)
      return !matcher.test(value)
    },
    message: VALIDATION_MESSAGES.mustNotMatch
  })

  extend('url', {
    validate: str => {
      var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ) // fragment locator
      return !!pattern.test(str)
    },
    message: VALIDATION_MESSAGES.url
  })

  extend('passwordMatch', {
    params: ['confirmPassword'],
    validate(value, { confirmPassword }: any) {
      return value === confirmPassword
    },
    message: VALIDATION_MESSAGES.passwordMatch
  })

  extend('individualDetails', {
    ...required,
    message: VALIDATION_MESSAGES.individualDetails
  })

  extend('fileExtension', {
    validate: value => /\.[a-zA-Z]+$/.test(value),
    message: VALIDATION_MESSAGES.fileExtension
  })

  extend('guid', {
    validate: value =>
      /^[0-9a-fA-F]{32}$|^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(value),
    message: VALIDATION_MESSAGES.guid
  })
  //this regex only validates this guid format with out "{" or "}"
}
