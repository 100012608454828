import { CURRENCY_FORMAT_OPTIONS, PERCENTAGE_FORMAT_OPTIONS } from '@/constants'
import { mutations as gqMutations, queries } from '@/graphql'
import { apolloClient } from '@/graphql/client'
import {
  DocumentFacility,
  DocumentVariant,
  FacilitySharedFacilityWithOrganisation,
  RecurringFeeType,
  SecurityType,
  UpdateDocumentFacilityInput
} from '@/graphql/types'
import { RootState } from '@/store'
import { toCurrency, toUTCDate } from '@/utils'
import { ToastProgrammatic } from 'buefy'
import { v4 as uuid } from 'uuid'
import { parse } from 'vue-currency-input'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

// Actions
export const FETCH_FACILITY = 'fetchFacility'
export const COMMIT_FACILITY = 'commitFacilityUpdates'

// Mutations
const SET_FACILITY = 'setFacility'
export const UPDATE_FACILITY = 'updateFacility'

// Getters
export const ORIGINAL = 'original'
export const UPDATED = 'updated'
export const CHANGES = 'changes'

export interface EditableDocumentFacility {
  [index: string]: any
  annualCost: string | null
  documentVariant: DocumentVariant
  endDate: Date
  limit: string
  name: string
  provider: string | null
  securityType: SecurityType
  setUpCost: string | null
  startDate: Date
  documentSetUpCost: string | null
  documentRecurringFixedCost: string | null
  documentRecurringPercentageCost: string | null
  documentRecurringCostFrequency: number | null
  documentAdjustedCost: boolean | null
  documentRecurringFeeType: RecurringFeeType
  sharedFacilityWithOrganisations: FacilitySharedFacilityWithOrganisation[] | null
}

interface FacilityState {
  [index: string]: any
  organisationId: string | null
  facilityId: string | null
  edited: EditableDocumentFacility | null
  original: DocumentFacility | null
}

const state: FacilityState = {
  organisationId: null,
  facilityId: null,
  edited: null,
  original: null
}

const getters: GetterTree<FacilityState, RootState> = {
  [ORIGINAL]: () => state.original,
  [UPDATED]: () => state.edited
}

const actions: ActionTree<FacilityState, RootState> = {
  [FETCH_FACILITY]({ commit }, { organisationId, facilityId }) {
    return apolloClient()
      .query({
        query: queries.FACILITY_BY_ID,
        variables: {
          facilityId: facilityId
        }
      })
      .then(({ data }) => {
        const facility = data.documentFacilityById
        commit(SET_FACILITY, {
          organisationId,
          facilityId,
          facility
        })
        return facility
      })
      .catch(error =>
        ToastProgrammatic.open({
          message: error.message,
          type: 'is-danger'
        })
      )
  },
  [COMMIT_FACILITY]({ dispatch }) {
    const { organisationId, facilityId } = state
    if (!organisationId || !facilityId) {
      return
    }
    const {
      annualCost,
      documentVariant,
      endDate,
      limit,
      name,
      provider,
      securityType,
      setUpCost,
      startDate,
      documentSetUpCost,
      documentRecurringFixedCost,
      documentRecurringPercentageCost,
      documentRecurringCostFrequency,
      documentAdjustedCost,
      documentRecurringFeeType,
      documentRecurringMinimumAnnualCost,
      sharedFacilityWithOrganisations
    } = state.edited!

    const facilityDocumentCosts = documentSetUpCost
      ? {
          documentSetUpCost: parse(documentSetUpCost, CURRENCY_FORMAT_OPTIONS),
          documentRecurringFixedCost: documentRecurringFixedCost
            ? parse(documentRecurringFixedCost, CURRENCY_FORMAT_OPTIONS)
            : null,
          documentRecurringPercentageCost: documentRecurringPercentageCost
            ? parse(documentRecurringPercentageCost, PERCENTAGE_FORMAT_OPTIONS)
            : null,
          documentRecurringCostFrequency,
          documentAdjustedCost,
          documentRecurringFeeType,
          documentRecurringMinimumAnnualCost: documentRecurringMinimumAnnualCost
            ? parse(documentRecurringMinimumAnnualCost, CURRENCY_FORMAT_OPTIONS)
            : null
        }
      : null

    const input: UpdateDocumentFacilityInput = {
      clientMutationId: uuid(),
      id: state.facilityId!,
      annualCost: annualCost ? parse(annualCost, CURRENCY_FORMAT_OPTIONS) : null,
      documentVariant,
      endDate: endDate ? toUTCDate(endDate) : undefined,
      limit: parse(limit, CURRENCY_FORMAT_OPTIONS),
      name,
      provider,
      securityType: documentVariant == DocumentVariant.BANK_GUARANTEE ? securityType : null,
      setUpCost: setUpCost ? parse(setUpCost, CURRENCY_FORMAT_OPTIONS) : null,
      startDate: toUTCDate(startDate),
      facilityDocumentCosts,
      sharedFacilityWithOrganisations: sharedFacilityWithOrganisations ? sharedFacilityWithOrganisations : []
    } as any

    return apolloClient()
      .mutate({
        mutation: gqMutations.UPDATE_FACILITY_MUTATION,
        variables: { input }
      })
      .then(() => {
        // Check if organisation still have access to facility as they could remove themselves from shared facility list
        if (
          state.original?.organisationId === organisationId ||
          sharedFacilityWithOrganisations?.includes(organisationId as any)
        )
          dispatch(FETCH_FACILITY, { organisationId, facilityId })
      })
      .catch(error =>
        ToastProgrammatic.open({
          message: error.message,
          type: 'is-danger'
        })
      )
  }
}

interface SetFacilityInput {
  organisationId: string
  facilityId: string
  facility: DocumentFacility
}
const mutations: MutationTree<FacilityState> = {
  [SET_FACILITY](state, { organisationId, facilityId, facility }: SetFacilityInput) {
    state.facilityId = facilityId
    state.organisationId = organisationId
    state.original = facility
    const {
      annualCost,
      documentVariant,
      endDate,
      limit,
      name,
      provider,
      securityType,
      setUpCost,
      startDate,
      documentSetUpCost,
      documentRecurringFixedCost,
      documentRecurringPercentageCost,
      documentRecurringCostFrequency,
      documentRecurringFeeType,
      documentRecurringMinimumAnnualCost,
      documentAdjustedCost,
      sharedFacilityWithOrganisations
    } = state.original

    state.edited = {
      annualCost: annualCost ? toCurrency(annualCost, true) : null,
      documentVariant,
      endDate: new Date(endDate),
      limit: toCurrency(limit, true),
      name,
      provider,
      securityType,
      setUpCost: setUpCost ? toCurrency(setUpCost, true) : null,
      startDate: new Date(startDate),
      documentSetUpCost: documentSetUpCost ? toCurrency(documentSetUpCost, true) : null,
      documentRecurringFixedCost: documentRecurringFixedCost ? toCurrency(documentRecurringFixedCost, true) : null,
      documentRecurringPercentageCost: documentRecurringPercentageCost ? documentRecurringPercentageCost + '%' : null,
      documentRecurringMinimumAnnualCost: documentRecurringMinimumAnnualCost
        ? toCurrency(documentRecurringMinimumAnnualCost, true)
        : null,
      documentRecurringCostFrequency,
      documentAdjustedCost: typeof documentAdjustedCost !== 'undefined' ? documentAdjustedCost : null,
      documentRecurringFeeType,
      sharedFacilityWithOrganisations: sharedFacilityWithOrganisations ? sharedFacilityWithOrganisations : []
    } as any
  },
  [UPDATE_FACILITY](state, changes: any) {
    if (!state.edited) return
    Object.entries(changes).forEach(change => {
      state.edited![change[0]] = change[1]
    })
  }
}

const mod: Module<FacilityState, RootState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}

export default mod
